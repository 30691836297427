<template>
  <div>
    <section class="video-section">
      <video autoplay muted playsinline loop preload="auto">
        <source src="@/assets/images/about/MO_about_time_lapse.webm" type="video/webm" />
        <source src="@/assets/images/about/MO_about_time_lapse.mp4" type="video/mp4" />
      </video>
      <div class="txt-wrap">
        <div class="txt1-cont">
          <h2>
            <span>
              멈추지 않는 고민은<br />
              언제나 새로운 길을<br />
              걷게 합니다.
            </span>
          </h2>
        </div>
        <div class="txt2-cont">
          <h2>
            <span>
              MAKE GREATNESS
              <span class="txt2">
                우리는 늘 경계를 허물고 확장시키며<br />
                새로운 경험을 만들어 냅니다.
              </span>
            </span>
          </h2>
        </div>
      </div>
      <div class="trigger"></div>
    </section>
    <div class="tab-contents">
      <tab-item v-if="!selectShow" :list="tab.list" :name="tab.name" v-model="tab.active" />
      <form-select
        v-else
        type="tab"
        v-model="tab.active"
        :options="
          tab.list.map(el => {
            return {
              value: String(el.id),
              label: el.title
            };
          })
        "
      />

      <!--  //introduction   -->
      <template v-if="tab.active === '0'">
        <div class="introduction">
          <div class="wrapper">
            <div class="group-01">
              <div class="contents">
                <p class="desc">
                  업력 28년, <br />
                  수많은 경험들 속에서 축적된 노하우를 바탕으로<br />
                  브랜드의 가치를 높이는 솔루션을 제공합니다.
                </p>
                <h2 class="title mt10">
                  끊임없이 고민하고 분석하며<br />
                  고객의 성공적인 비즈니스를 실현하는,<br />
                  NO.1 디지털 에이전시입니다.
                </h2>
              </div>
              <div class="lottie-cont lottie01">
                <lottie-about01 />
              </div>
            </div>
            <!--            <div class="video">-->
            <!--              <div class="video-title">-->
            <!--                <p>Great Performance</p>-->
            <!--                <p>Great Opportunity</p>-->
            <!--                <p>Great People</p>-->
            <!--                <p>Great Culture</p>-->
            <!--              </div>-->
            <!--              <div class="video-bg">-->
            <!--                <video autoplay muted playsinline loop preload="auto">-->
            <!--                  <source src="@/assets/images/about/MO_about_time_lapse.mp4" type="video/mp4" />-->
            <!--                </video>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

          <div class="group group-02">
            <div class="contents">
              <h2 class="title">
                누구보다 역동적으로 나아가며<br />
                세상을 놀라게 하는<br />
                위대한 결과물을 만들어 냅니다.
              </h2>
              <ul class="list">
                <li>
                  <strong>Radical Thinking</strong>
                  <p class="desc">
                    다양한 현상과 이슈에 대해 극단적으로 질문하며<br />
                    문제가 해결될 때까지 분석과 검증을 반복하여<br />
                    논리적인 설계 과정을 만들어갑니다.
                  </p>
                </li>
                <li>
                  <strong>Geek Working</strong>
                  <p class="desc">
                    작은 요소에도 집착하여 우리만의 차이를 만들어가며<br />
                    프로젝트의 시작과 끝을 체계적으로 정의합니다.
                  </p>
                </li>
              </ul>
            </div>
            <div class="lottie-cont lottie-02">
              <lottie-about02 />
            </div>
          </div>
          <div class="group group-03">
            <div class="contents">
              <h2 class="title">
                상장사의 안전성과<br />
                우수한 기술력을 기반으로<br />
                대한민국 WEB 3.0의 선두를<br />
                이끌어 갑니다.
              </h2>
              <ul class="list">
                <li>
                  <strong>Digital Experts</strong>
                  <p class="desc">
                    <span>UX 컨설팅 / 구축 / 운영</span>
                    UX 컨설팅부터 기획, 디자인, WEB/APP 개발<br />
                    및 운영까지 이르는 원활한 A to Z 프로세스를 제공합니다.
                  </p>
                </li>
                <li>
                  <strong>AI Experts</strong>
                  <p class="desc">
                    <span>AI Digital Solution / Data Analysis & Visualization</span>
                    데이터 자동화와 생성형 AI 기술을 통해 실질적이고<br />
                    실행 가능한 비즈니스 가치를 제공합니다.
                  </p>
                </li>
                <li>
                  <strong>Blockchain · AD Tech Experts</strong>
                  <p class="desc">
                    <span>메타버스·포인트 / NFT / 블록체인 / 캐시 팩토리</span>
                    NFT와 메타버스 기술을 활용해 WEB 3.0 환경을 조성하며,<br />
                    새로운 리워드 플랫폼으로 기업의 디지털 전환을 돕습니다.
                  </p>
                </li>
              </ul>
            </div>
            <div class="lottie-cont lottie-03">
              <lottie-about03 />
            </div>
          </div>
          <div class="group group-04">
            <div class="contents">
              <h2 class="title">
                우리는 사람을 향한<br />
                따뜻한 감성과 이성적 사고를<br />
                겸비한 디지털 전문가 집단입니다.
              </h2>
              <div class="desc">
                다양한 실전 경험과 지식을 갖춘 전문가들이<br />
                당신의 사업 파트너가 되어 높은 수준의 구축과<br />
                안정적인 운영 서비스를 제공합니다.
              </div>
              <ul class="figure-list">
                <li>
                  <strong>1,500</strong>
                  <span>프로젝트 수행 경험</span>
                </li>
                <li>
                  <strong>15<em>Years</em></strong>
                  <span>PM 평균 경력</span>
                </li>
                <li>
                  <strong>140</strong>
                  <span>전문가 수</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="images">
            <div class="image">
              <span class="ceo-name">왼쪽부터 대표이사 강승진, 대표이사 최복규</span>
              <img :src="require('@/assets/images/about/07_img01.png')" alt="CEO" />
            </div>
            <div class="image">
              <img :src="require('@/assets/images/about/img_02.webp')" alt="awards" />
            </div>
            <div class="image">
              <img :src="require('@/assets/images/about/img_03.webp')" alt="CEO" />
            </div>
          </div>
          <div class="group group-05">
            <div class="contents">
              <h2 class="title">
                이모션글로벌과 함께라면<br />
                그 어떤 것도 할 수 있고,<br />
                어떤 것이라도 될 수 있습니다.
              </h2>
              <div class="desc">
                다양한 크리에이터들과 경계 없는 협업을 통해<br />
                지속 가능한 기회를 만들어 냅니다.<br />
                우리의 입체적인 시선과 경험으로<br />
                새로운 인사이트를 제시합니다.
              </div>
            </div>
            <div class="lottie-cont lottie-05">
              <lottie-about04 />
            </div>
          </div>
          <div class="group group-06 contents">
            <h2 class="title">
              사고의 폭과 깊이를 확장시키는<br />
              수평적이고 자유로운 분위기 속에서<br />
              함께 성장합니다.
            </h2>
            <div class="desc">
              우리는 각자의 개성과 관점을 존중하고 서로 다름을 이해하며 공동의 목표를 향해
              나아갑니다.<br />
              동료를 향한 신뢰는 유연한 팀 문화를 만들고<br />
              그 속에서 몰입을 통한 폭발적인 성장을 경험하게 됩니다.
            </div>
            <div class="images">
              <div class="image">
                <img :src="require('@/assets/images/about/image 365.webp')" alt="업무 이미지" />
              </div>
              <div class="image">
                <img
                  :src="require('@/assets/images/about/MO_02_img_02 1.webp')"
                  alt="업무 이미지"
                />
              </div>
              <div class="image">
                <img :src="require('@/assets/images/about/MO_img_03 1.webp')" alt="업무 이미지" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--  introduction//   -->

      <!--  //History   -->
      <template v-else-if="tab.active === '1'">
        <div class="history">
          <h2 class="title-color contents">
            다년간의 경험으로 생긴 역량을 바탕으로<br />
            <em>우리는 멈추지 않고 계속해서 나아갑니다.</em>
          </h2>
          <div class="award-list" v-for="(awardYear, YearIndex) in awardList" :key="YearIndex">
            <h3 class="year-title">{{ awardYear.year }}</h3>
            <div class="recent" v-if="YearIndex === 0">
              <div class="inner">
                <h4 class="title">
                  함께 이룬 30년,<br />
                  끊임없이 이어가는 새로운 경험
                </h4>
                <p class="txt">
                  우리는 지난 30년 동안 더욱 완벽한 목표를 향해 끊임없이 나아가며,<br />
                  축적된 노하우와 창의적 아이디어를 결합해 업계를 선도하는<br />
                  새로운 기준을 만들어가고 있습니다.<br /><br />
                  변화하는 디지털 환경 속에서 선두를 이끌어가며 <br />
                  세상을 놀라게 하는 위대한 결과물을 지속적으로 만들어냅니다.
                </p>
              </div>
              <ul class="year-list">
                <li v-for="(item, index) in awardYear.totalList[0].list" :key="index">
                  <h4 class="title">{{ item.title }}</h4>
                  <span class="year">
                    <span ref="yearRefs"> {{ animatedYears[index] }}</span>
                    <i>{{ item.year_sub }}</i>
                  </span>
                  <p class="txt" v-html="item.txt"></p>
                </li>
              </ul>
            </div>
            <div class="previous" v-else>
              <div class="previous-swiper" v-swiper:[`mySwiper${YearIndex}`]="swiperOptions2">
                <ul class="swiper-wrapper swiper-area">
                  <li
                    class="swiper-slide swiper-item"
                    v-for="(totalItem, totalListIndex) in awardYear.totalList"
                    :key="totalListIndex"
                  >
                    <h4 class="title" :class="{active: totalItem.list.some(el => el.active)}">
                      {{ totalItem.year }}
                    </h4>
                    <ul class="list">
                      <li
                        v-for="(item, index2) in totalItem.list"
                        :key="index2"
                        :class="{active: item.active}"
                      >
                        <p v-html="item.contents"></p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--  //History   -->

      <!--  //Award   -->
      <template v-else-if="tab.active === '2'">
        <div class="contents award">
          <h2 class="title-color">
            이모션글로벌은<br />
            전문가들의 각기 다른 재능으로<br />
            <em>크리에이티브한 결과물을 도출해냅니다.</em>
          </h2>
          <ul class="work-list">
            <li v-for="item in prizeData" :key="item.id">
              <router-link :to="`/works/${item.id}`">
                <div class="thumb">
                  <img :src="imgUrl(item.moSmallImagePhysicalName)" alt="" />
                </div>
                <div class="info">
                  <i class="circle" />
                  <div class="title">
                    <div class="name">{{ item.projectServiceName }}</div>
                    <div class="prize" v-for="(prize, index) in item.prizeList" :key="index">
                      {{ prize.prize }}
                    </div>
                  </div>
                  <span class="more">Read More</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </template>
      <!--  Award//   -->
    </div>
  </div>
</template>
<script>
  import {TweenMax, Power0, Power2} from "gsap";
  import {apiPrizeList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import LottieAbout01 from "@/components/lottie/about01";
  import LottieAbout02 from "@/components/lottie/about02";
  import LottieAbout03 from "@/components/lottie/about03";
  import LottieAbout04 from "@/components/lottie/about04";

  export default {
    name: "About",
    components: {LottieAbout01, LottieAbout02, LottieAbout03, LottieAbout04},
    metaInfo() {
      return {
        title: "About",
        titleTemplate: "%s | e · motion"
      };
    },
    mixins: [commonMethods],
    data() {
      return {
        animatedYears: [], // 숫자 값 저장,
        tab: {
          name: "about-tab",
          list: [
            {
              id: "0",
              title: "Introduction"
            },
            {
              id: "1",
              title: "History"
            },
            {
              id: "2",
              title: "Award"
            }
          ],
          active: "0"
        },
        scene: null,
        page: 0,
        size: 5,
        loading: false,
        prizeData: [],
        swiperOptions: {
          autoplay: {delay: 5000},
          loop: true,
          speed: 700,
          effect: "fade",
          fadeEffect: {
            crossFade: true
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
            renderBullet: (index, currentClass) => {
              return `
              <div class="${currentClass}">
                <span class="title">${this.awardList[0].totalList[0].list[index].title}</span>
                <span class="text">${this.awardList[0].totalList[0].list[index].contents}</span>
              </div>`;
            }
          },
          slidesPerView: "auto"
        },
        swiperOptions2: {
          slidesPerView: "auto"
        },
        awardList: [
          {
            year: 2025,
            totalList: [
              {
                year: 2025,
                list: [
                  {
                    title: "Years",
                    year: "1995",
                    txt:
                      "1995년부터 지금까지, <br/>모든 산업분야에서 <br/> 디지털 세상의 <br/> 새로운 경험을 제공합니다."
                  },
                  {
                    title: "Projects",
                    year: "1500",
                    year_sub: "+",
                    txt:
                      "1,500여 건의 <br/>프로젝트 수행<br/>경험을 기반으로 <br/>최상의 시너지를 발휘합니다."
                  },
                  {
                    title: "Awards",
                    year: "214",
                    txt:
                      "각기 다른 재능을 가진 <br/>140여 명의 전문가들이 <br/>함께 일하고 있습니다."
                  },
                  {
                    title: "People",
                    year: "140",
                    txt:
                      "평균 15년 경력의 PM들이 <br/>든든한 파트너가되어 <br/>안정적인 운영이 가능합니다."
                  },
                  {
                    title: "Senior PM",
                    year: "15",
                    year_sub: "Years",
                    txt:
                      "연매출 100억원대의<br/>최고 실적과 안정적인 <br/>재무상태를 가지고 있습니다."
                  },
                  {
                    title: "Clients",
                    year: "500",
                    year_sub: "+",
                    txt:
                      "각 분야 최고의 전문가들과<br/>합리적인 전략과 방안을<br/>제시하며 세상이 놀랄<br/>결과물을 만들어냅니다."
                  }
                ]
              }
            ]
          },
          {
            year: 2020,
            totalList: [
              {
                year: 2024,
                list: [
                  {
                    contents: "서울반도체<br/>&어워드 중견기업 분야 GRAND PRIX"
                  },
                  {
                    contents: "OCI<br/>&어워드 대기업 분야 WINNER"
                  },
                  {
                    contents: "신세계<br/>2024 ICT 어워드코리아<br/>디지털 서비스혁신 분야"
                  },
                  {
                    contents:
                      "SK텔레콤<br/>2024 ICT 어워드코리아 디지털 서비스혁신 분야<br/>i-AWARDS KOREA 정보서비스 혁신 대상"
                  },
                  {
                    contents:
                      "OCI<br/>2024 ICT 어워드코리아 디지털 커뉴니케이션 분야<br/>i-AWARDS KOREA ​금속/화학업분야 대상"
                  },
                  {
                    contents: "서울반도체<br/>i-AWARDS KOREA ​ 부품분야 대상"
                  }
                ]
              },
              {
                year: 2023,
                list: [
                  {
                    contents: "핑거버스<br/>웹어워드코리아 서비스 <br/>생활서비스 부분 통합대상"
                  },
                  {
                    contents: "와이넵<br/>앱어워드2023 식음료 분야 대상"
                  },
                  {
                    contents: "삼성전자<br/>앱어워드 모바일마케팅<br/>대상"
                  }
                ]
              },
              {
                year: 2022,
                list: [
                  {
                    contents: "T 다이렉트<br/>웹어워드2022 서비스<br/>이노베이션 대상"
                  },
                  {
                    contents: "LG이노텍<br/>웹어워드2022 소재1부품 분야 대상"
                  },
                  {
                    contents: "JAJU<br/>앱어워드2022<br/>분야 대상"
                  },
                  {
                    contents: "이베스트온<br/>앱어워드2022<br/>최우수상"
                  }
                ]
              }
            ]
          },
          {
            year: 2010,
            totalList: [
              {
                year: 2019,
                list: [
                  {
                    contents: "<span>FSN 사옥 이전<br><br></span>"
                  },
                  {
                    contents: "CJ제일제당 CJ THE MARKET"
                  },
                  {
                    contents: "웹어워드2019 쇼핑몰부문 대상 수상 &어워드  GRAND PRIX 수상"
                  }
                ]
              },
              {
                year: 2018,
                list: [
                  {
                    contents: "<span>FSN 합류<br><br></span>",
                    active: true
                  },
                  {
                    contents: "애경산업 FFLOW"
                  },
                  {
                    contents: "웹어워드2018 쇼핑몰부문 대상 수상"
                  }
                ]
              },
              {
                year: 2017,
                list: [
                  {
                    contents: "<span>SK텔레콤 T전화 iF 디자인어워드 수상<br><br></span>",
                    active: true
                  },
                  {
                    contents: "<span>LG전자 디자인 협력업체 선정<br><br></span>"
                  },
                  {
                    contents: "YDM  buzz 통합"
                  }
                ]
              },
              {
                year: 2016,
                list: [
                  {
                    contents: "신사옥 이전(신사동)"
                  }
                ]
              },
              {
                year: 2014,
                list: [
                  {
                    contents: "<span>(주)옐로모바일 합류 <br><br></span>",
                    active: true
                  },
                  {
                    contents: "<span>기업부설연구소 설립<br><br></span>"
                  },
                  {
                    contents: "(주)옐로디지털마케팅(YDM) 합류"
                  }
                ]
              },
              {
                year: 2013,
                list: [
                  {
                    contents: "<span>장애인차별금지법을 위한 웹 접근성 출범<br><br></span>"
                  },
                  {
                    contents: "디지털마케팅그룹 출범 "
                  }
                ]
              },

              {
                year: 2011,
                list: [
                  {
                    contents: "크리에이티브 컨설팅을 위한 UX그룹 출범"
                  }
                ]
              },
              {
                year: 2010,
                list: [
                  {
                    contents: "<span>국내최초위치기반모바일 SNS ‘1km’ 출시<br><br></span>"
                  },
                  {
                    contents: "웹어워드2010 최고대상 수상 (CJ그룹)"
                  }
                ]
              }
            ]
          },
          {
            year: 2000,
            totalList: [
              {
                year: 2008,
                list: [
                  {
                    contents: "<span>미국 LA 현지법인 설립  e·motion CA, Inc.<br><br></span>"
                  },
                  {
                    contents: " 미국 현대자동차 홈페이지 구축 및  현지 운영 (2008~2011년)"
                  }
                ]
              },
              {
                year: 2006,
                list: [
                  {
                    contents: "벤처기업지정 (기술평가보증기업)"
                  }
                ]
              },
              {
                year: 2005,
                list: [
                  {
                    contents: "일본 동경 현지법인 설립 株式會社 e·motion",
                    active: true
                  }
                ]
              },
              {
                year: 2004,
                list: [
                  {
                    contents: "대한민국 초고속성장 기술기업 50 우수상 (Deloitte & Touche)"
                  },
                  {
                    contents: "<span>대한민국 벤쳐기업대상 국무총리상 수상<br><br></span>"
                  },
                  {
                    contents:
                      "미국 뉴욕페스티벌 Interactive Awards Best Website Design (Nike.co.kr)"
                  }
                ]
              },
              {
                year: 2003,
                list: [
                  {
                    contents:
                      "<span>대한민국 초고속성장 기술기업 50 우수상 장 (Deloitte & Touche)<br><br></span>"
                  },
                  {
                    contents: "대한민국 벤쳐기업대상 국무총리상 수상"
                  }
                ]
              },
              {
                year: 2002,
                list: [
                  {
                    contents:
                      "코스닥(KOSDAQ) 등록 및 최연소CEO기록 (2002.08) 정주형, 만28세 크리에이티브"
                  }
                ]
              },
              {
                year: 2001,
                list: [
                  {
                    contents:
                      "한국일보와 대학교수 전문가그룹이 선정한 21세기를 대표할 21개 IT기업 선정",
                    active: true
                  }
                ]
              },
              {
                year: 2000,
                list: [
                  {
                    contents: "<span>Icti, VoIP 전문기업 ‘신양정보통신’ 인수<br><br></span>"
                  },
                  {
                    contents: "전경련 선정 우수벤처기업 선정 (총60개 벤쳐기업 중 2위) "
                  }
                ]
              }
            ]
          },
          {
            year: 1990,
            totalList: [
              {
                year: 1998,
                list: [
                  {
                    contents:
                      "대한민국 경찰청 홈페이지 구축 및 인터넷 대상 정부부문 1위 수상 (Yahoo)"
                  }
                ]
              },

              {
                year: 1997,
                list: [
                  {
                    contents:
                      "Web programing SW ‘CGI Builder’ 개발  및 신 SW상품대상 수상 (정보통신부)"
                  }
                ]
              },
              {
                year: 1995,
                list: [
                  {
                    contents: "<span>e·motion 창립<br>(1995.12)</span>",
                    active: true
                  }
                ]
              }
            ]
          }
        ],
        selectShow: false
      };
    },
    watch: {
      "tab.active"(val1) {
        if (val1 === "2") {
          this.$nextTick(() => {
            this.awardAni();
          });
        }
        if (val1 === "1") {
          this.$nextTick(() => {
            this.initAnimatedYears();
            this.initGSAPScrollTrigger();
          });
        }
      }
    },
    activated() {
      this.videoAni();
    },
    methods: {
      initAnimatedYears() {
        this.animatedYears = [];
        this.awardList.forEach(awardYear => {
          awardYear.totalList.forEach(totalItem => {
            totalItem.list.forEach(() => {
              this.animatedYears.push(0);
            });
          });
        });
      },
      initGSAPScrollTrigger() {
        this.$nextTick(() => {
          if (!this.$refs.yearRefs) return;
          const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5 // 요소의 50%가 보일 때 콜백 실행
          };

          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                // 해당 요소가 보이면 애니메이션 실행
                this.$refs.yearRefs.forEach((yearEl, index) => {
                  const targetNumber = this.getYearFromAwardList(index);
                  if (isNaN(targetNumber)) return;
                  let animatedValue = {value: 0};
                  TweenMax.to(animatedValue, 1.5, {
                    value: targetNumber,
                    ease: Power2.easeOut,
                    onUpdate: () => {
                      yearEl.innerText = Math.floor(animatedValue.value);
                    }
                  });
                });
                // 한 번 실행된 후에는 옵저버 해제
                observer.disconnect();
              }
            });
          }, observerOptions);

          // 각 요소에 대해 옵저버 적용
          this.$refs.yearRefs.forEach(yearEl => {
            observer.observe(yearEl);
          });
        });
      },
      getYearFromAwardList(index) {
        let count = 0;
        for (let i = 0; i < this.awardList.length; i++) {
          for (let j = 0; j < this.awardList[i].totalList.length; j++) {
            for (let k = 0; k < this.awardList[i].totalList[j].list.length; k++) {
              if (count === index) {
                return parseInt(this.awardList[i].totalList[j].list[k].year);
              }
              count++;
            }
          }
        }
        return 0;
      },
      videoAni() {
        this.scene?.destroy(true);
        const tw1 = TweenMax.fromTo(
          document.querySelector(".txt1-cont h2"),
          0.35,
          {
            y: 120,
            opacity: 1
          },
          {
            y: 0,
            opacity: 0,
            ease: Power2.easeInOut
          }
        );

        const tw2 = TweenMax.fromTo(
          document.querySelector(".txt2-cont h2"),
          0.35,
          {
            y: 240,
            opacity: 0
          },
          {
            y: 120,
            opacity: 1,
            ease: Power2.easeInOut
          }
        );
        const tween0 = this.$scrollmagic
          .scene({
            offset: 0,
            triggerElement: document.querySelector(".trigger"),
            triggerHook: 0
          })
          .setTween([tw1, tw2]);
        this.$scrollmagic.addScene(tween0);
      },
      awardAni() {
        document.querySelectorAll(".section, .work-list li").forEach(el => {
          const tw = TweenMax.fromTo(
            el,
            0.5,
            {
              y: 100
            },
            {
              y: 0,
              ease: Power0.easeInOut
            }
          );
          const sc = this.$scrollmagic
            .scene({
              offset: 0,
              triggerElement: el,
              triggerHook: 1,
              duration: 500
            })
            .setTween(tw);
          this.$scrollmagic.addScene(sc);
        });
      },
      async fetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiPrizeList({
            page: this.page,
            size: this.size
          });
          this.prizeData = this.prizeData.concat(response.content);
          console.log(response);
          this.loading = false;
        } catch (error) {
          console.error(error.response);
        }
      },
      windowScrollEvt() {
        const tabContents = document.querySelector(".tab-contents");
        if (tabContents) {
          if (tabContents.offsetTop <= window.scrollY + 1) {
            this.selectShow = true;
          } else {
            this.selectShow = false;
          }
        }
      }
    },
    created() {
      this.fetchData();
      this.$nextTick(() => {
        this.videoAni();
      });
    },
    mounted() {
      this.initAnimatedYears();
      this.initGSAPScrollTrigger();
      document.addEventListener("scroll", this.windowScrollEvt);
    },
    destroyed() {
      document.removeEventListener("scroll", this.windowScrollEvt);
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .tab-contents {
    padding: 0 0 80px;
  }
  .video-section {
    position: relative;
    width: 100%;
    height: 200vh;
    z-index: 99;
    background: black;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
    video {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      z-index: 1;
    }
    .trigger {
      position: absolute;
      left: 0;
      top: 30vh;
      width: 100%;
      height: 100vh;
    }
    .txt-wrap {
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      > div {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        h2 {
          position: absolute;
          top: 0;
          left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: -120px;
          height: 100vh;
          font-weight: 700;
          font-size: 30px;
          line-height: 43px;
          color: #fff;
          > span {
            height: 369px;
          }
        }
      }
      .txt2-cont {
        h2 {
          margin-top: -120px;
          @include montserrat();
          .txt2 {
            display: block;
            margin-top: 10px;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            font-family: "Noto Sans KR", sans-serif;
          }
        }
      }
    }
  }

  // introduction ------------------------------------
  .wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: -40px;
  }

  .introduction {
    margin-top: 76px;
    .desc {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      letter-spacing: -0.05em;
    }

    .title {
      margin-top: 125px;
      font-size: 20px;
      line-height: 33px;
      font-weight: 700;
      letter-spacing: -0.03em;
      color: #000;

      &.mt10 {
        margin-top: 10px;
      }
    }

    .lottie-cont {
      min-width: 320px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .group {
      margin-top: 125px;
    }
    .group-01 {
      .contents {
        position: relative;
        z-index: 2;
      }
      position: relative;
      .lottie01 {
        transform: translateY(-40px);
        width: 100vw;
        z-index: 1;

        .lottie-container {
          width: 100%;
        }
      }
    }
    .video {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 225px;
      background: black;
      color: white;
      min-width: 320px;
      width: 100%;
      height: 300px;
      &-title {
        width: 100%;
        z-index: 10;
        padding: 50px 0 0 20px;
        p {
          font-size: 20px;
          line-height: 20px;
          font-family: Montserrat, "Noto Sans KR", sans-serif;
          font-weight: bold;
          letter-spacing: -0.03em;
          & + p {
            margin-top: 10px;
          }
        }
      }

      &-bg {
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }
      }
    }
    .group-02,
    .group-03 {
      .list {
        margin-top: 50px;
        li {
          border-top: 1px solid #ddd;
          padding-top: 25px;

          & + li {
            margin-top: 50px;
          }

          strong {
            position: relative;
            padding-left: 13px;
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            font-family: Montserrat, "Noto Sans KR", sans-serif;
            letter-spacing: -0.03em;

            &::before {
              content: "";
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              position: absolute;
              width: 6px;
              height: 6px;
              background-color: $red;
              border-radius: 50%;
            }
          }

          .desc {
            margin-top: 10px;
          }
        }
      }

      .lottie-cont {
        margin-top: 75px;
      }
    }

    .group-02 {
      margin-top: 120px;
      .title {
        margin-top: 0;
      }
    }

    .group-03 {
      .desc {
        span {
          display: block;
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.05em;
        }
      }
      .lottie-03 {
        min-height: 305px;
      }
    }

    .group-04 {
      .desc {
        margin-top: 10px;
      }
      .figure-list {
        display: flex;
        margin-top: 50px;
        flex-wrap: wrap;

        li {
          padding-top: 25px;
          border-top: 1px solid #ddd;

          &:first-child {
            width: 100%;
            padding-bottom: 50px;
          }
          &:nth-child(2) {
            flex: 1;
            margin-right: 30px;
          }
          &:nth-child(3) {
            flex: 1;
          }

          strong {
            position: relative;
            font-size: 30px;
            line-height: 43px;
            font-weight: bold;
            font-family: Montserrat, "Noto Sans KR", sans-serif;
            letter-spacing: -0.03em;
            em {
              position: absolute;
              margin-top: 9px;
              left: calc(100% + 2px);
              font-size: 14px;
              line-height: 14px;
            }
          }
          span {
            display: block;
            margin-top: 5px;
            font-size: 14px;
            line-height: 12px;
            font-weight: 400;
            color: #a0a0a0;
          }
        }
      }
    }

    .images {
      margin-top: 75px;
      .image {
        position: relative;
        .ceo-name {
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 1;
          color: #000;
          @include montserrat();
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        & + .image {
          margin-top: 5px;
        }
      }
    }

    .group-05 {
      .desc {
        margin-top: 10px;
      }
      .lottie-cont {
        margin-top: 25px;
      }
    }

    .group-06 {
      .desc {
        margin-top: 10px;
      }
    }
  }

  .title-color {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.05em;
    color: #a0a0a0;

    em {
      color: $red;
    }
  }

  // history ------------------------------------
  .history {
    margin-top: 76px;
    & + .contents {
      .section-tit {
        padding-top: 69px;
      }
    }
    .year-title {
      @include montserrat();
      padding: 0 20px;
      font-size: 36px;
      color: $red;
      font-weight: 900;
    }
    .award-list {
      &:nth-of-type(1) {
        margin-top: 50px;
      }
      &:nth-of-type(2) {
        margin-top: 60px;
      }
      margin-top: 45px;
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        .swiper-item:nth-of-type(3) {
          p {
            line-height: 18px;
            transform: translateY(-3px);
          }
        }
      }
    }
    .recent {
      position: relative;
      margin-top: 50px;
      .inner {
        padding: 0 20px;
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: -0.9px;
        }
        .txt {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.6px;
        }
      }
      .year-list {
        margin-top: 40px;
        background: $red;
        display: flex;
        flex-wrap: wrap;
        padding: 40px 34px;
        column-gap: 28px;
        box-sizing: border-box;
        li {
          width: calc((100% - 28px) / 2);
          &:nth-child(5) {
            .year i {
              margin-left: 5px;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: -0.8px;
            }
          }
          color: #fff;
          padding: 10px 0 36px;
          border-top: 1px solid #ddd;
          .title {
            font-size: 16px;
            font-weight: 400;
            line-height: 34px;
          }
          .year {
            @include montserrat();
            display: inline-flex;
            margin-top: 10px;
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: -1.5px;
          }
          .txt {
            margin-top: 10px;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: -0.66px;
          }
        }
      }
    }
    .previous {
      position: relative;
      margin-top: 17px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        top: 35px;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: #dddbdb;
      }
      .previous-swiper {
        overflow: visible;
        .swiper-wrapper {
          .swiper-slide {
            width: calc((100% - 100px) / 2);
            margin-left: 20px;
          }
          .title {
            @include montserrat();
            font-size: 18px;
            color: #a0a0a0;
            letter-spacing: -0.05em;
            &.active {
              font-weight: 900;
              color: $red;
            }
          }
          .list {
            margin-top: 38px;
            padding-right: 10px;
            &:before {
              content: "";
              display: block;
              margin-bottom: 13px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #ee2c3c;
            }
            li {
              font-size: 11px;
              line-height: 14px;
              font-weight: bold;
              word-break: keep-all;
              letter-spacing: -0.05em;
              & + li {
                margin-top: 3px;
              }
              &.active {
                font-weight: bold;
                color: $red;
              }
            }
          }
        }
      }
    }
  }

  // award ------------------------------------
  .award {
    margin-top: 76px;
  }
  .work-list {
    flex-wrap: wrap;
    margin: 50px 0 0;
    li {
      margin-right: 40px;
      + li {
        margin-top: 80px;
      }
      &:nth-child(even) {
        margin: 80px 0 0 40px;
      }
      .thumb {
        overflow: hidden;
        img {
          width: 100%;
          transition: all 0.3s ease-in-out;
        }
      }
      .info {
        position: relative;
        margin: 20px 0 0;
        padding: 22px 0 0;
        .circle {
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #ee2c3c;
        }
        .title {
          .name {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.05em;
            color: #000;
          }
          .prize {
            font-size: 11px;
            line-height: 19px;
            font-weight: 400;
            color: #a0a0a0;
          }
        }
        .more {
          @include montserrat;
          display: inline-block;
          position: relative;
          margin: 29px 0 0;
          padding: 0;
          font-size: 13px;
          line-height: 15px;
          transition: all 0.3s ease-in-out;
          color: #000;
          &::before {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: #000;
            transition: all 0.3s ease-in-out;
          }
          &::after {
            display: block;
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            width: 6px;
            height: 1px;
            background: #ee2c3c;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &:hover,
      &:focus {
        .thumb {
          img {
            transform: scale(1.1);
          }
        }
        .info {
          .more {
            padding: 0 21px 0 0;
            color: #ee2c3c;
            &::before {
              background: #ee2c3c;
            }
            &::after {
              z-index: 1;
              transform-origin: right bottom;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
</style>
